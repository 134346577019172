import { Placement } from '@popperjs/core'
import React, { ReactElement, useState } from 'react'

import Details from '@components/JourneyList/Emission/Details'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Trans } from '@lib/i18n'
import { Icon, Modal, Tooltip } from '@ui'

import '@components/JourneyList/Emission/index.scss'

interface EmissionProps {
  typicalEmission: number
  currentEmission: number
  savingPercentage: number
  position: Placement
  reduced?: boolean
}

const Emission = ({ reduced, position, ...rest }: EmissionProps): ReactElement => {
  const [opened, setOpened] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const enhance = <span className={bem('journey-list', 'emission', { enhance: true })} />

  const handleOpenModal = (): void => {
    isMobile && setOpened(!opened)
  }

  const content = !isMobile ? <Details {...rest} /> : null

  return (
    <div className="row journey-list__emission">
      <div className={bem('journey-list', 'emission-leaves')}>
        <Icon name="leaves" size="large" />
      </div>
      <span>
        <Trans
          i18nKey={`journeyList.emission.${reduced ? 'mainReduced' : 'main'}`}
          components={{ enhance, sub: <sub /> }}
          values={{ percentage: `${rest.savingPercentage}%` }}
        />
      </span>
      <Tooltip action="hover" content={content} position={position} contentClassName="journey-list__emission-tooltip">
        <div className={bem('journey-list', 'emission-info')}>
          <Icon name="info" size="medium" onClick={handleOpenModal} />
        </div>
      </Tooltip>
      {isMobile && (
        <Modal opened={opened} onClose={handleOpenModal}>
          <Details onClick={handleOpenModal} {...rest} />
        </Modal>
      )}
    </div>
  )
}

export default Emission
