import React, { ReactElement, useState } from 'react'

import Emission from '@components/JourneyList/Emission'
import Collection from '@components/MediaCollection'
import CarrierLogo from '@components/TripInfo/CarrierLogo'
import FareFeatures from '@components/TripInfo/FareFeatures'
import FareLabel from '@components/TripInfo/FareLabel'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'

import '@components/TripInfo/CarrierInfo/index.scss'

interface CarrierInfoProps {
  carrier: Carrier
  fareFeatures: FareFeature[]
  fareClassNames: string[]
  line?: string
  linePrefix?: string
  lineDataEnabled: boolean
  connection: Connection
  media?: string[] | null
}

const CarrierInfo = (props: CarrierInfoProps): ReactElement => {
  const { carrier, fareFeatures, fareClassNames, linePrefix, line, lineDataEnabled, connection, media } = props
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)

  const emission = connectionUtils.calculateEmission([connection])
  const showCollection = !isMobile && media && media.length > 0
  const showLogo = !showCollection && !isMobile

  return (
    <div className="carriers">
      {showLogo && (
        <CarrierLogo
          name={carrier.tradeName}
          code={carrier.code}
          line={line}
          linePrefix={linePrefix}
          lineDataEnabled={lineDataEnabled}
        />
      )}
      <div className={bem('carriers', 'fares')}>
        {showCollection && (
          <div className="carriers__media row center items-center mb-3" onClick={() => setOpened(true)}>
            <CarrierLogo name={carrier.tradeName} code={carrier.code} />
            <Collection media={media} opened={opened} onClick={() => setOpened(false)} />
          </div>
        )}
        {!isMobile && fareClassNames.map(name => <FareLabel key={name} fareClassName={name} />)}
        <FareFeatures fareFeatures={fareFeatures} media={!!showCollection} />
        {emission && (
          <div className={bem('carriers', 'fares-emission')}>
            <Emission position="top-end" {...emission} reduced />
          </div>
        )}
      </div>
    </div>
  )
}

export default CarrierInfo
