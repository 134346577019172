import { BookingAncillaries, BookingPassenger, GovernmentIdParams } from '@api/booking'
import makeApi from '@lib/api'

export interface NormalRequest {
  arrivalStationCode: StationCode
  departureStationCode: StationCode
  currency: Currency
  locale: Locale
  marketingCarrierCode: CarrierCode
  departureTime: string
  arrivalTime: string
  retailerPartnerNumber: number
  fareClass: string | null
  passengers: BookingPassenger[]
  returnDepartureTime?: string
  returnArrivalTime?: string
  returnDepartureDate?: string
  returnArrivalDate?: string
  termsVersion?: string | null
  city?: string | null
  state?: string | null
  zipCode?: string | null
  countryCode?: string | null
  streetAndNumber?: string | null
  cards?: DiscountCode.Card[] | null
  retailerBookingNumber?: string | null
  returnFareClass?: string | null
}

export interface QuickReservationPassenger extends BookingPassenger {
  type: string
  lastName: string
  firstName: string
  gender: string
}

export interface QuickRequest extends NormalRequest, GovernmentIdParams {
  reservationHold: boolean
  title: string
  firstName: string
  lastName: string
  email: string
  phone: string | null
  termsAccepted: boolean
  gender: string
  passengers: QuickReservationPassenger[]
  ancillaries?: BookingAncillaries[]
  returnFareClass?: string | null
}

export type Request = NormalRequest | QuickRequest

export interface Response {
  createdAt: string
  expiresAt?: string
  id: number
  bookingFormId: number
  price: Money
  fees: Fee[]
}

export const create = makeApi.post<{}, Request, Response>({
  type: 'reservations',
  old: '/reservations',
  new: '/reservations',
})
