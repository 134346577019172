import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import Amenity from '@components/JourneyCard/Footer/Amenity'
import Deck from '@components/SeatSelection/Deck'
import Footer from '@components/SeatSelection/Footer'
import Header from '@components/SeatSelection/Header'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import fareUtils from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import selectionUtils from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Modal, Tabs } from '@ui'

import '@components/SeatSelection/index.scss'

interface SeatSelectionProps {
  opened: boolean
  showHeader: boolean
  layout: Seat.Data[]
  connection: Connection | null
  selected: Seat.Entry[]
  onNavigateBack: () => void
  reservedSeatsCount: number
  segmentIndex: number
  onSelect: (seat: Seat.Entry) => void
  onSubmit: () => void
  onClose: () => void
  onClick?: () => void
  selectedFareClassCode: string | null
  outboundSeatsCount?: number
  isFullPrice?: boolean
}

const getInitialLevel = (layout: Seat.Data, fareClassCode: string): number => {
  return layout?.cars[0].seats.find(item => item.fareClass === fareClassCode)?.coordinates.z ?? 0
}

const isSeat = (seat: Seat.Entry): boolean => seat.code !== 'WC' && seat.code !== 'ES'

const SeatSelection = ({
  opened,
  showHeader,
  layout,
  connection,
  selected,
  reservedSeatsCount,
  segmentIndex,
  onSelect,
  onSubmit,
  onClose,
  onClick,
  onNavigateBack,
  selectedFareClassCode,
  outboundSeatsCount = 0,
  isFullPrice,
}: SeatSelectionProps): ReactElement => {
  const [fareClassCode, setFareClassCode] = useState<string>('')
  const [deckLevel, setDeckLevel] = useState<number>(0)
  const [{ currency }] = useParams()
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { cars, segment } = { ...layout[segmentIndex] }
  const seats = cars?.flatMap(car => car.seats) ?? /* istanbul ignore next */ []

  useEffect(() => {
    const fareClassCode = selectedFareClassCode || selectionUtils.getUniqueFares(layout[segmentIndex])[0]
    const deckLevel = getInitialLevel(layout[segmentIndex], fareClassCode)

    setFareClassCode(fareClassCode)
    setDeckLevel(deckLevel)
  }, [layout, segmentIndex, selectedFareClassCode, opened])

  useEffect(() => {
    if (fareClassCode) {
      setDeckLevel(getInitialLevel(layout[segmentIndex], fareClassCode))
    }
  }, [fareClassCode, layout, segmentIndex, opened])

  const sumPrice = useMemo(() => {
    const fractional = utils.array.sum(selected, ({ price, fareClass }) => {
      const fare = isFullPrice ? connection?.fares?.find(el => el.fareClass.code === fareClass) : null
      const farePrice: number = fare?.price?.fractional ?? /* istanbul ignore next */ 0

      return Number(price) + farePrice
    })

    return { fractional, currency }
  }, [connection, currency, isFullPrice, selected])

  const deckTabs = useMemo(
    () =>
      selectionUtils.getUniqueLevel(layout[segmentIndex], fareClassCode).map(item => ({
        key: item,
        label: item === 0 ? t('seats.lower_deck') : t('seats.upper_deck'),
      })),
    [layout, segmentIndex, t, fareClassCode],
  )

  const faresTabs = useMemo(
    () =>
      selectionUtils
        .getUniqueFares(layout[segmentIndex])
        .map(item => ({
          key: item,
          label: utils.string.capitalize(
            fareUtils.getFareByCode(item, connection)?.fareClass.name ?? /* istanbul ignore next */ '',
          ),
        }))
        .filter(item => item.label),
    [connection, layout, segmentIndex],
  )

  const filteredSeats = useMemo(() => {
    const levels = cars?.flatMap(item => item.seats.filter(el => el.coordinates.z === deckLevel))

    return fareClassCode
      ? levels?.filter(item => item.fareClass === fareClassCode || !item.fareClass || !isSeat(item))
      : levels
  }, [cars, deckLevel, fareClassCode])

  const amenities = useMemo(() => {
    const fareFeature = fareUtils.getFareByCode(fareClassCode, connection)

    return fareFeature?.fareClass.fareFeatures.map(item => (
      <Amenity key={item.id} fareFeature={item} tooltipPosition="bottom" />
    ))
  }, [connection, fareClassCode])

  const handleChangeFareClass = (fareClassCode: string): void => {
    setFareClassCode(fareClassCode)
    onClick?.()
  }

  const handleChangeDeckLevel = (level: number): void => {
    setDeckLevel(level)
  }

  const header =
    showHeader && layout.length ? (
      <Header
        segment={segment}
        connection={connection}
        onNavigateBack={onNavigateBack}
        segmentsCount={layout.length}
        segmentIndex={segmentIndex}
      />
    ) : null

  const priceCategories = selectionUtils.getPriceCategories(seats)

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('seats.title')}
      header={header}
      fullScreen={isMobile}
      className={bem('seat-selection', 'modal')}
      footer={
        <Footer
          reservedSeatsCount={reservedSeatsCount}
          passengerCount={selected.length}
          outboundSeatsCount={outboundSeatsCount}
          onClick={onSubmit}
          price={sumPrice}
        />
      }
    >
      <>
        <div className="seat-selection__tabs column row-lg space-between">
          <div className={bem('seat-selection', 'tabs-fares')}>
            <Tabs options={faresTabs} value={fareClassCode} onClick={handleChangeFareClass} />
          </div>
          {deckTabs.length > 1 && (
            <div className={bem('seat-selection', 'tabs-level')}>
              <Tabs options={deckTabs} value={deckLevel} variant="outlined" onClick={handleChangeDeckLevel} />
            </div>
          )}
        </div>
        <Deck
          seats={filteredSeats}
          priceCategories={priceCategories}
          onClick={onSelect}
          selected={selected}
          amenities={amenities}
        />
      </>
    </Modal>
  )
}

export default SeatSelection
