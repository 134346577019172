import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useState } from 'react'

import useSearchParams from '@hooks/useSearchParams'
import currencyUtils from '@lib/currency'
import Message from '@pages/Checkout/Form/PriceDiscrepancy/Message'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@pages/Checkout/Form/PriceDiscrepancy/index.scss'

const PriceDiscrepancy = (): ReactElement => {
  const [{ price: paramsPrice, currency, pax }] = useParams()
  const [query] = useSearchParams()
  const [{ reservation }] = useSettings()
  const { values } = useFormikContext<CheckoutFormData>()
  const [showNotification, setShowNotification] = useState<boolean>(true)

  const price = reservation.enabled ? values.reservationData?.price : values.vacancy?.price

  const currentPrice = price?.fractional && currencyUtils.getPriceInEuro(price as Money)
  const showMessage = !!paramsPrice && !!currentPrice && paramsPrice !== currentPrice && showNotification

  useEffect(() => {
    const currencyChanged = price?.currency && price.currency !== currency
    const paxChanged = query.pax && query.pax != values.passengers.length
    const changed = currencyChanged || paxChanged

    changed && setShowNotification(false)
  }, [currency, pax, price, query.pax, values.passengers.length])

  return (
    <>
      {showMessage && (
        <Message savedPrice={paramsPrice} currentPrice={currentPrice} onClose={() => setShowNotification(false)} />
      )}
    </>
  )
}

export default PriceDiscrepancy
