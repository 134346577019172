import seatsAPI, { SeatsRequest, SeatsResponse } from '@api/seats'
import { makeLoader } from '@lib/loader'

interface UseSeatsLoaderProps {
  enabled: boolean
  params: SeatsRequest
}

export const useSeatsLoader = makeLoader<UseSeatsLoaderProps, SeatsResponse>({
  key: 'seats',
  loader: async ({ params }) => await seatsAPI.load(params),
  enabled: ({ params, enabled }) => Object.values(params).every(item => item) && enabled,
})
