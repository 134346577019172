import cn from 'classnames'
import React, { useState } from 'react'

import MenuItem from '@components/Header/Item'
import useIsMobile from '@hooks/useIsMobile'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Accordion, Icon, Tooltip } from '@ui'

interface MenuGroupProps extends NavBar.Group {
  level: number
}

const MenuGroup = (props: MenuGroupProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'header' })
  const { items, translationKey, level, onLinkClick } = props
  const [opened, setOpened] = useState(false)
  const isMobile = useIsMobile()

  const content = items.map(item => (
    <div key={item.translationKey} className="cell body-16 header-menu__nested-item">
      <MenuItem {...item} level={level + 1} onLinkClick={onLinkClick} />
    </div>
  ))

  const handleClick = (name: string) => {
    amplitude.common.clickMenu(name)
  }

  if (isMobile)
    return (
      <Accordion
        divider={false}
        title={
          <div onClick={() => handleClick(t(translationKey))} className={'header-menu__group-title'}>
            {t(translationKey)}
          </div>
        }
      >
        <div className={cn('header-menu__group', `level-${level}`)}>{content}</div>
      </Accordion>
    )

  const upperLevel = level === 0

  const actions = upperLevel ? 'click' : 'hover'
  const position = upperLevel ? 'bottom-start' : 'right-start'
  return (
    <Tooltip
      className={bem('header-menu', 'group', { upperLevel, opened })}
      contentClassName={bem('header-menu', 'group-tooltip')}
      onOpenChanged={setOpened}
      action={actions}
      content={content}
      position={position}
    >
      <div className="row items-center center" onClick={() => handleClick(t(translationKey))}>
        <div className="cell">{t(translationKey)}</div>
        <div className="cell no-grow">
          <Icon className="header-menu__group-icon" name="chevron-right" size="small" />
        </div>
      </div>
    </Tooltip>
  )
}

export default MenuGroup
