import { t } from 'i18next'
import React, { ReactElement } from 'react'

import { Button } from '@ui'
import { ButtonProps } from '@ui/Button'

const DefaultPayButton = (props: Partial<ButtonProps>): ReactElement => (
  <Button buttonType="submit" {...props}>
    {t('checkout.payButton')}
  </Button>
)

export default DefaultPayButton
