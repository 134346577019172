import mediaAPI, { MediaRequest, MediaResponse } from '@api/media'
import { makeLoader } from '@lib/loader'

interface UseMediaLoaderProps {
  enabled: boolean
  params: MediaRequest
}

export const useMediaLoader = makeLoader<UseMediaLoaderProps, MediaResponse>({
  key: 'media',
  loader: async ({ params }) => await mediaAPI.load(params),
  enabled: ({ enabled, params }) => enabled && !!params.marketingCarrierCode,
})
