import { useState } from 'react'
import { QueryObserverResult } from 'react-query'

import { ApiError } from '@lib/api'
import seatSelectionUtils from '@lib/seatSelection'
import { useSeatsLoader } from '@loaders/seats'
import { useParams } from '@stores/params'

export type SeatsList = Record<string, Seat.Entry[]>

interface UseSeatSelectionResult {
  selectedSeats: SeatsList
  handleSelect: (seat: Seat.Entry) => void
  confirmedSeats: SelectedSeats
  handleClose: () => void
  handleConfirm: () => void
  handleNavigateBack: () => void
  resetSelected: () => void
  isLoading: boolean
  layout: Seat.Data[]
  segmentIndex: number
  segmentType: ConnectionType
  reset: () => void
  refetchOutbound: () => Promise<QueryObserverResult<Seat.Data[], ApiError>>
}

interface SeatsLayout {
  outbound: Seat.Data[]
  inbound: Seat.Data[]
}

export interface SelectedSeats {
  outbound: SeatsList
  inbound: SeatsList
}

interface UseSeatSelectionProps {
  connections: { outbound: Connection | null; inbound: Connection | null }
  onSuccess?: () => void
  onError?: (error: ApiError) => void
  onConfirm: () => void
  enabled: boolean
}

const initialValue = { outbound: { 0: [] }, inbound: { 0: [] } }

const useSeatSelection = ({
  connections,
  onSuccess,
  onConfirm,
  onError,
  enabled,
}: UseSeatSelectionProps): UseSeatSelectionResult => {
  const [layout, setLayout] = useState<SeatsLayout>({ outbound: [], inbound: [] })
  const [type, setType] = useState<ConnectionType>('outbound')
  const [selectedSeats, setSelectedSeats] = useState<SelectedSeats>(initialValue)
  const [confirmedSeats, setConfirmedSeats] = useState<SelectedSeats>(initialValue)
  const [index, setIndex] = useState<number>(0)
  const [{ locale, currency }] = useParams()

  const { isLoading: outboundsLoading, refetch: refetchOutbound } = useSeatsLoader(
    {
      params: { ...seatSelectionUtils.buildSeatsParams(connections.outbound, locale, currency) },
      enabled,
    },
    {
      onSuccess: data => {
        setLayout(prevState => ({ ...prevState, outbound: data }))
        onSuccess?.()
      },
      onError: data => {
        onError?.(data)
      },
    },
  )

  const { isLoading: inboundsLoading } = useSeatsLoader(
    {
      params: { ...seatSelectionUtils.buildSeatsParams(connections.inbound, locale, currency) },
      enabled,
    },
    {
      onSuccess: data => {
        setLayout(prevState => ({ ...prevState, inbound: data }))
        onSuccess?.()
      },
      onError: /* istanbul ignore next */ data => {
        onError?.(data)
      },
    },
  )

  const handleSelect = (seat: Seat.Entry): void => {
    const isInArray = selectedSeats[type][index].some(item => item.code === seat.code)
    const isSameFare = selectedSeats[type][index].map(({ fareClass }) => fareClass).includes(seat.fareClass)
    const newSeats = isSameFare ? selectedSeats[type][index] : []
    const seats = isInArray ? selectedSeats[type][index].filter(item => item.code !== seat.code) : [...newSeats, seat]

    setSelectedSeats({ ...selectedSeats, [type]: { ...selectedSeats[type], [index]: seats } })
  }

  const changeType = (type: ConnectionType): void => {
    setType(type)
    setIndex(0)
  }

  /* istanbul ignore next */
  const handleClose = (): void => {
    window.addEventListener(
      'animationend',
      () => {
        changeType('outbound')
        setSelectedSeats(confirmedSeats)
      },
      { once: true },
    )
  }

  const handleConfirm = (): void => {
    setConfirmedSeats({ ...confirmedSeats, [type]: { ...confirmedSeats[type], [index]: selectedSeats[type][index] } })

    if (layout[type][index + 1] !== undefined) {
      setIndex(index + 1)
      selectedSeats[type][index + 1] === undefined &&
        setSelectedSeats({ ...selectedSeats, [type]: { ...selectedSeats[type], [index + 1]: [] } })

      return
    }

    if (layout.inbound.length && type === 'outbound') {
      changeType('inbound')

      return
    }

    window.addEventListener(
      'animationend',
      () => {
        changeType('outbound')
      },
      { once: true },
    )
    onConfirm()
  }

  const handleNavigateBack = (): void => {
    setIndex(index - 1)
  }

  const resetSelected = (): void => {
    setSelectedSeats({ ...selectedSeats, [type]: { ...initialValue[type], [index]: [] } })
  }

  const reset = (): void => {
    setSelectedSeats(initialValue)
    setConfirmedSeats(initialValue)
    changeType('outbound')
  }

  return {
    selectedSeats: selectedSeats[type],
    layout: layout[type],
    isLoading: outboundsLoading || inboundsLoading,
    segmentIndex: index,
    segmentType: type,
    confirmedSeats,
    handleSelect,
    handleClose,
    handleConfirm,
    handleNavigateBack,
    resetSelected,
    reset,
    refetchOutbound,
  }
}

export default useSeatSelection
