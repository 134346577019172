import React, { FunctionComponent } from 'react'

import Ancillary from '@pages/Checkout/Extras/Ancillary/index'
import InsuranceAncillary from '@pages/Checkout/Extras/Ancillary/Insurance'
import { ResolverProps } from '@pages/Checkout/Extras/Ancillary/Resolver'

const PETS = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const INSURANCE = () => <InsuranceAncillary />
const EQUIPMENT = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const BICYCLE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const HELP = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const SEAT = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const LUGGAGE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const VEHICLE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const MEAL = (props: ResolverProps) => <Ancillary.Resolver {...props} />

const Ancillaries: Record<string, FunctionComponent<ResolverProps>> = {
  PETS,
  INSURANCE,
  EQUIPMENT,
  BICYCLE,
  HELP,
  SEAT,
  LUGGAGE,
  VEHICLE,
  MEAL,
}

export default Ancillaries
