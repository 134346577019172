import React, { ReactElement } from 'react'

import LogoLink from '@components/Logo/Link'
import { useSettings } from '@queries/settings'

import '@components/Logo/index.scss'

const Logo = (): ReactElement | null => {
  const [{ logo }] = useSettings()
  const { url } = logo

  if (!url) return null

  return (
    <div className="partner-logo">
      <LogoLink>
        <img src={url} alt="Logo" data-tag="logo" />
      </LogoLink>
    </div>
  )
}

export default Logo
