import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorBoundary from '@components/ErrorBoundary'
import amplitude from '@lib/analytics/amplitude'
import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import paramUtils from '@lib/params'
import url from '@lib/url'
import PopularDirectionCard from '@pages/Search/PopularDirectionCard'
import { PopularDirectionParams } from '@pages/Search/PopularDirectionCard/Body'
import { useParams } from '@stores/params'

import '@pages/Search/PopularDirections/index.scss'

interface PopularDirectionsProps {
  connections: PopularConnection[]
}

const getConnectionKey = ({ departureLocation, arrivalLocation }: PopularConnection): string =>
  departureLocation.code + arrivalLocation.code

const PopularDirections = ({ connections }: PopularDirectionsProps): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [paramsStore] = useParams()
  const { currency } = paramsStore

  const navigateToResultPage = (params: PopularDirectionParams): void => {
    const { passengers, departureDate, departureLocation, arrivalLocation } = params
    const updatedParams = {
      ...paramUtils.flatLocation(departureLocation, 'departure'),
      ...paramUtils.flatLocation(arrivalLocation, 'arrival'),
      departureDate: date.formatDate(departureDate),
      pax: passengers[0].pax,
    }

    const uri = url.build(['/result'], {
      ...updatedParams,
      ...paramUtils.getPersistentUrlParams(paramsStore),
      currency,
    })

    navigate(uri)
  }

  const onBookButtonClick = (params: PopularDirectionParams, index: number): void => {
    amplitude.clickPopularDirection({ ...params, position: index + 1 })
    navigateToResultPage(params)
  }

  return (
    <ErrorBoundary>
      <div className="popular-directions">
        <div className="column gap-6">
          <div className={'cell'}>
            <h3 className="mb-0">{t('popularDirections.title')}</h3>
          </div>
          <div className="cell">
            <div className="gap-6 row-lg row-md column wrap">
              {connections.map((connection, index) => (
                <div key={getConnectionKey(connection)} className="cell-lg-3 cell-md-6 cell">
                  <PopularDirectionCard
                    connection={connection}
                    onClick={params => {
                      onBookButtonClick(params, index)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default PopularDirections
