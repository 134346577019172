import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo } from 'react'

import ancillaryUtils from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'
import { useFareClassesLoader } from '@loaders/fareClasses'
import FareClassItem from '@pages/Checkout/FareClassesSelection/SplitFareClasses/FareClassItem'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Accordion, Icon } from '@ui'

import '@pages/Checkout/FareClassesSelection/SplitFareClasses/index.scss'

interface SplitFareClassesProps {
  media?: Media.Data | null
}

const findTravelClass = (list: FareClassMap.Entity[] | null, fareClass?: string | null): string | undefined =>
  list?.find(item => item.fares.findIndex(el => el.fareClassCode === fareClass) >= 0)?.class

const SplitFareClasses = ({ media }: SplitFareClassesProps): ReactElement => {
  const { t } = useTranslation()
  const [{ outbound, inbound }] = useCheckout()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const [{ marketingCarrierCode }] = useParams()
  const { vacancy, fareClass, returnFareClass, ancillaries } = values
  const showReturnTrip = returnFareClass
  const isLoading = !outbound

  const accommodations = useMemo(() => ancillaryUtils.getByCategory('ACCOMMODATION', vacancy?.ancillaries), [vacancy])
  const outboundIndexes = new Array(outbound?.segments.length).fill(1).map((_, index) => index)
  const inboundIndexes = new Array(inbound?.segments.length)
    .fill(1)
    .map((_, index) => index + Number(outbound?.segments.length))

  const faresLoader = useFareClassesLoader(
    { params: { marketingCarrierCode: marketingCarrierCode as string }, enabled: true },
    {
      onSuccess: data => {
        setFieldValue('travelClass', findTravelClass(data, fareClass))
      },
    },
  )

  useEffect(() => {
    returnFareClass && inbound && setFieldValue('returnTravelClass', findTravelClass(faresLoader.data, returnFareClass))
  }, [faresLoader.data, inbound, returnFareClass, setFieldValue])

  useEffect(() => {
    const filteredAccommodations = accommodations.filter(item =>
      ancillaries.ACCOMMODATION.some(el => el.code === item.code && el.segmentIndex === item.segmentIndex),
    )

    const preselect = accommodations.reduce((acc: Ancillary.Item[], curr) => {
      if (acc.some(item => item.segmentIndex === curr.segmentIndex)) return acc

      return [...acc, curr]
    }, filteredAccommodations)

    setFieldValue('ancillaries.ACCOMMODATION', preselect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accommodations, fareClass, returnFareClass, setFieldValue])

  const getTitle = (connection: Connection | null, type: 'outbound' | 'inbound'): ReactElement | null => (
    <div className="row-lg items-center">
      <h3 className="cell-lg-4">{t(`checkout.splitFareClass.${type}`)}</h3>
      <div className="row">
        <h4 className="mr-3">{connection?.departureStation.city.name}</h4>
        <Icon className="mr-3" name="arrow-right" size="large" />
        <h4>{connection?.arrivalStation.city.name}</h4>
      </div>
    </div>
  )

  return (
    <>
      {showReturnTrip && (
        <div className="column fare-class__wrapper">
          <Accordion title={getTitle(outbound, 'outbound')} divider={false} opened>
            <FareClassItem
              faresLoader={faresLoader}
              connection={outbound}
              isConnectionsLoading={isLoading}
              selectedFare={inbound?.fares.find(inbound => inbound.fareClass.code === returnFareClass)}
              accommodations={accommodations.filter(acc => outboundIndexes.some(i => i === acc.segmentIndex))}
            />
          </Accordion>
          <Accordion title={getTitle(inbound, 'inbound')} divider={false} opened>
            <FareClassItem
              faresLoader={faresLoader}
              connection={inbound}
              isConnectionsLoading={isLoading}
              selectedFare={outbound?.fares.find(outbound => outbound.fareClass.code === fareClass)}
              accommodations={accommodations.filter(acc => inboundIndexes.some(i => i === acc.segmentIndex))}
              isReturnTrip
            />
          </Accordion>
        </div>
      )}
      {!showReturnTrip && (
        <div className="column gap-4">
          <FareClassItem
            faresLoader={faresLoader}
            connection={outbound}
            isConnectionsLoading={isLoading}
            accommodations={accommodations}
            media={media}
          />
        </div>
      )}
    </>
  )
}

export default SplitFareClasses
