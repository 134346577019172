import React, { FocusEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'

import currencyUtils from '@lib/currency'
import { useParams } from '@stores/params'
import { Input } from '@ui'
import { InputElement } from '@ui/Input'

interface Props {
  value: number
  onChange: (value: number) => void
  transformOnSubmit: (value: number) => number
  label: string
}

const PriceInput = (props: Props) => {
  const { onChange, label, value, transformOnSubmit } = props
  const [{ currency }] = useParams()

  const getDecimalsMultiplier = (input: string) => {
    const decimalsAmount = input.split(currencyUtils.getDecimal(currency))[1]?.length ?? 0
    return 10 ** (currencyUtils.getPrecision(currency) - decimalsAmount)
  }

  const parseInput = (input: string): number => Number(input.match(/\d/g)?.join('')) * getDecimalsMultiplier(input)
  const stringifyInput = useCallback(
    (fractional: number) => currencyUtils.create(fractional, currency, { separator: '' }).format(),
    [currency],
  )

  const [inputValue, setInputValue] = useState<string>(() => stringifyInput(value))

  const submitValue = (e: FocusEvent<InputElement> | KeyboardEvent<InputElement>) => {
    const transformedValue = transformOnSubmit(parseInput(e.currentTarget.value))
    onChange(transformedValue)
    setInputValue(stringifyInput(transformedValue))
  }

  useEffect(() => {
    setInputValue(stringifyInput(value))
  }, [stringifyInput, value])

  const onInputChange = (value: string) => {
    const allowedCharacters = value.match(/[\d.,]/g)?.join('') ?? ''
    setInputValue(`${currencyUtils.getSymbol(currency)}${allowedCharacters}`)
  }

  return (
    <Input value={inputValue} onChange={onInputChange} onBlur={submitValue} onPressEnter={submitValue} label={label} />
  )
}

export default PriceInput
