import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import Price from '@pages/Checkout/BookingDetails/Price'
import DefaultPayButton from '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Default'
import Voucher from '@pages/Checkout/BookingDetails/Voucher'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

import '@pages/Checkout/BookingDetails/SubmitSection/index.scss'

const SubmitSection = (): ReactElement => {
  const [{ paymentInstance }] = useCheckout()
  const [{ discountVoucher }] = useSettings()
  const {
    values: { isReservationLoading, isVacancyLoading },
  } = useFormikContext<CheckoutFormData>()
  const isPriceLoading = isReservationLoading || isVacancyLoading

  return (
    <div className="submit-section">
      {discountVoucher.enabled && (
        <div className="mb-3">
          <Voucher />
        </div>
      )}
      <Price />
      <div data-tag="pay">
        {isPriceLoading && <DefaultPayButton disabled />}
        {!isPriceLoading && (paymentInstance?.getPayButton?.() ?? <DefaultPayButton />)}
      </div>
    </div>
  )
}

export default SubmitSection
