import React, { memo, ReactElement, useState } from 'react'

import { MediaResponse } from '@api/media'
import Body from '@components/JourneyCard/Body'
import Details from '@components/JourneyCard/Details'
import Fares from '@components/JourneyCard/Details/Fares'
import Footer from '@components/JourneyCard/Footer'
import Header from '@components/JourneyCard/Header'
import Preview from '@components/JourneyCard/Media/Preview'
import useIsMobile from '@hooks/useIsMobile'
import fareUtils from '@lib/fare'
import fareClassUtils from '@lib/fareClass'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Card } from '@ui'

interface JourneyCardProps {
  connection: Connection
  onDetailsOpen?: (connection: Connection) => void
  onClick: (connection: Connection, fareClassCode: string) => void
  selectedFareClass: string | null
  isInbound: boolean
  disableFareFilter: boolean
  media?: MediaResponse | null
}

const JourneyCard = (props: JourneyCardProps): ReactElement => {
  const { connection, onClick, selectedFareClass, onDetailsOpen, isInbound, disableFareFilter, media } = props
  const [{ pax, bookingId }] = useParams()
  const [{ fareClasses, vehicleIdentifier, carrierLogo, media: mediaSetting }] = useSettings()
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)
  const [fareClass, setFareClass] = useState<string>(selectedFareClass ?? connection.cheapestFareClassCode)
  const { t } = useTranslation()
  const toggleDetails = (): void => {
    setOpened(!opened)
    if (!opened) {
      onDetailsOpen?.(connection)
    }
  }

  const faresCondition = fareClasses.displayOn === 'search_results' || fareClasses.displayOn === 'everywhere'
  const filteredMedia = media && [...media.images, ...media.videos, ...fareClassUtils.getMedia(media, fareClass)]
  const mediaCondition = !isMobile && mediaSetting.preview && !!filteredMedia?.length
  const bestFare = fareUtils.getFareByCode(selectedFareClass ?? connection.cheapestFareClassCode, connection)
  const handleFareClassClick = (fareClass: string): void => {
    setOpened(true)
    setFareClass(fareClass)
  }

  return (
    <Card
      id={connection.id}
      key={connection.id}
      dataTag="connection-card"
      opened={opened}
      header={carrierLogo.enabled && <Header connection={connection} onClick={onClick} />}
      body={
        <Body
          onClick={onClick}
          connection={connection}
          fareSelection={faresCondition}
          lineDataEnabled={vehicleIdentifier.enabled}
          isHeaderHidden={!carrierLogo.enabled}
        />
      }
      footer={
        <Footer
          connection={connection}
          onToggleDetails={toggleDetails}
          onClick={onClick}
          passengersCount={pax}
          fare={bestFare}
          showFares={faresCondition}
          isAmendment={!!bookingId}
          isHeaderHidden={!carrierLogo.enabled}
        />
      }
      details={
        <Details
          connection={connection}
          opened={opened}
          onToggleDetails={() => {
            setOpened(!opened)
          }}
          onClick={onClick}
          showFares={faresCondition}
          bestFare={bestFare}
          selectedFareClass={selectedFareClass}
          displayedFareClass={fareClass}
          onFareClick={handleFareClassClick}
          isAmendment={!!bookingId}
          isInbound={isInbound}
          disableFareFilter={disableFareFilter}
          media={media}
        />
      }
      rightSide={
        faresCondition && (
          <Fares
            onClick={onClick}
            connection={connection}
            selectedFareClass={selectedFareClass}
            displayedFareClass={fareClass}
            onFareClick={handleFareClassClick}
            isAmendment={!!bookingId}
            isInbound={isInbound}
            disableFareFilter={disableFareFilter}
          />
        )
      }
      leftSide={
        mediaCondition && (
          <Preview
            media={filteredMedia.slice(0, 2)}
            onClick={() => {
              setOpened(!opened)
            }}
          />
        )
      }
      disabled={connection.bookedOut}
      disabledLabel={t('card.booked')}
    />
  )
}

export default memo(JourneyCard)
