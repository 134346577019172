import { useMemo } from 'react'

import { VacancyRequest } from '@api/vacancy'
import date from '@lib/date'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'
import { PassengerData, useCheckout } from '@stores/checkout'
import { useCarrierSpecificParams } from '@stores/params'

export const useVacancyProps = (passengers: PassengerData[]): VacancyRequest => {
  const [params] = useCarrierSpecificParams()
  const [{ outbound }] = useCheckout()
  const [{ ancillaries, paxDiscountCodes }] = useSettings()

  const passengerTypes = useMemo(() => {
    const filteredPassengers = passengersUtils.filterPassengers(passengers)
    const passengersMap = filteredPassengers.reduce<Record<string, Passenger.Param>>((accum, { type, pax, cards }) => {
      /* istanbul ignore next */
      if (type in accum) accum[type] = { type, cards, pax: (accum[type]?.pax ?? 0) + 1 }
      else accum[type] = { type, cards, pax }

      return accum
    }, {})
    const passengersDiscountsMap = filteredPassengers.map(({ type, pax, cards }) => ({ type, pax, cards }))

    return paxDiscountCodes.enabled ? passengersDiscountsMap : Object.values(passengersMap)
  }, [paxDiscountCodes, passengers])

  return useMemo(() => {
    const passengers = passengerTypes.map(pax => ({
      ...pax,
      cards: pax.cards?.map(({ name, code }) => ({ name, code })),
    }))
    const departure = outbound ? date.formatTime(date.parse(outbound.departureTime, 'UTC')) : params.departureTime
    const arrival = outbound ? date.formatTime(date.parse(outbound.arrivalTime, 'UTC')) : params.arrivalTime

    return utils.object.compact<VacancyRequest>({
      marketingCarrierCode: params.marketingCarrierCode,
      retailerPartnerNumber: params.retailerPartnerNumber,
      departureStationCode: params.departureLocation?.code,
      arrivalStationCode: params.arrivalLocation?.code,
      currency: params.currency,
      locale: params.locale,
      fareClass: params.fareClass,
      passengers,
      departureDate: params.departureDate,
      departureTime: departure,
      arrivalDate: params.arrivalDate,
      arrivalTime: arrival,
      returnDepartureDate: params.returnDepartureDate,
      returnDepartureTime: params.returnDepartureTime,
      returnArrivalDate: params.returnArrivalDate,
      returnArrivalTime: params.returnArrivalTime,
      include: ancillaries.enabled ? 'ancillary_options' : null,
      cards: params.cards,
    })
  }, [outbound, ancillaries, params, passengerTypes])
}
