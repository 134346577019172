import { Field, useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import Money from '@components/Money'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import DropdownField from '@ui/Dropdown/Field'

const InstallmentSelector = (): ReactElement => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CheckoutFormData>()
  const { installments } = values

  if (installments.length === 0) return <></>

  const options = installments.map(installment => {
    const text =
      installment.term === 1 ? (
        <b>{t('checkout.installments.onePayment')}</b>
      ) : (
        <>
          {installment.term}
          {' x '}
          <b>
            {' '}
            <Money {...installment.monthly} />
          </b>
        </>
      )

    return {
      value: installment.term,
      label: (
        <div className="row">
          <div className="cell-6">{text}</div>
          <div className="cell-6">
            {t('checkout.installments.total')}
            {':'}{' '}
            <b>
              <Money {...installment.total} />
            </b>
          </div>
        </div>
      ),
    }
  })

  return <Field name="numberOfInstallments" component={DropdownField} items={options} tag="installments-selector" />
}

export default InstallmentSelector
