import ApplePayElement from '@adyen/adyen-web/components/ApplePay'
import Core from '@adyen/adyen-web/core'
import { setNestedObjectValues, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams } from '@hooks/useBookingFlow'
import AdyenPayButton from '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Adyen'

interface AdyenPaymentEvent {
  browserInfo: any
  paymentMethod: any
}

interface AdyenPaymentState {
  data: AdyenPaymentEvent
}

export interface PayButtonInstanceParams {
  onSubmit: (state: AdyenPaymentState) => void
  validate: () => Promise<void>
  adyen?: Core
}

type BuildInstanceFunc = (params: PayButtonInstanceParams) => AdyenTypes.PayButtonElement | undefined

const useAdyenPayButtonMethod = (
  buildInstance: BuildInstanceFunc,
  adyen?: Core,
): Omit<AdyenTypes.Method, 'getOption'> => {
  const [status, setStatus] = useState(PaymentMethodStatus.Pending)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [instance, setInstance] = useState<ApplePayElement>()
  const { submitForm, setFieldValue, validateForm, setTouched } = useFormikContext()

  useEffect(() => {
    if (formSubmitted) {
      void submitForm()
      setFormSubmitted(false)
    }
  }, [formSubmitted, submitForm])

  const setPaymentData = useCallback(
    (event: AdyenPaymentEvent): void => {
      setFieldValue('browserInfo', event.browserInfo)
      setFieldValue('paymentMethodData', event.paymentMethod)
    },
    [setFieldValue],
  )

  const onSubmit = useCallback(
    (state: AdyenPaymentState): void => {
      setPaymentData(state.data)
      setFormSubmitted(true)
    },
    [setPaymentData],
  )

  const validate = useCallback(async (): Promise<void> => {
    const errors = await validateForm()
    const isValid = Object.keys(errors).length === 0

    if (!isValid) {
      setTouched(setNestedObjectValues(errors, true))
      await Promise.reject(new Error('invalid form fields'))
    }
    await Promise.resolve()
  }, [setTouched, validateForm])

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (!adyen) return

        const instance = buildInstance({ validate, onSubmit, adyen })
        const isAvailable = (await instance?.isAvailable()) as boolean
        if (!isAvailable) throw new Error('Method unavailable')

        setInstance(instance)
        setStatus(PaymentMethodStatus.Ready)
      } catch (e) {
        setStatus(PaymentMethodStatus.Rejected)
      }
    }

    void initialize()
  }, [adyen, buildInstance, onSubmit, validate])

  const confirmPayment = useCallback(
    (params: ConfirmPaymentParams) => {
      if (params.action === 'three_d_secure' && instance) {
        // istanbul ignore else
        if (instance.elementRef) {
          // By default, the elementRef node equals to the payment button element, which causes the pay button
          // to be replaced with 3DS iframe when its `three_d_secure` action is handled.
          // We dont want to replace the button and we want to display the 3DS iframe elsewhere in the page,
          // so we set the ref node to the intended iframe container element and then handle the action.
          instance.elementRef._node = document.getElementById('three-d-secure-iframe-portal')
        }
        instance.handleAction(params.details.action)
      }
    },
    [instance],
  )

  const getPayButton = useCallback(() => <AdyenPayButton instance={instance} />, [instance])

  return useMemo(
    () => ({
      getPayButton,
      status,
      on: {
        confirmPayment,
      },
    }),
    [confirmPayment, getPayButton, status],
  )
}

export default useAdyenPayButtonMethod
