import useDelayedPayment from '@pages/Checkout/hooks/Payment/Common/useDelayedPayment'
import useHoldReservation from '@pages/Checkout/hooks/Payment/Common/useHoldReservation'
import { PaymentProvider } from '@pages/Checkout/hooks/Payment/useBookingPayment'

export const useCommonMethods = (): PaymentProvider => {
  const cash = useDelayedPayment({ type: 'cash' })
  const terminal = useDelayedPayment({ type: 'terminal' })
  const invoice = useDelayedPayment({ type: 'invoice' })
  const holdReservation = useHoldReservation()

  return { cash, terminal, invoice, holdReservation }
}
