import React, { ReactElement, useState } from 'react'

import { Sorting } from '@enums'
import useIsMobile from '@hooks/useIsMobile'
import useSearchParams from '@hooks/useSearchParams'
import amplitude from '@lib/analytics/amplitude'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Dropdown, Icon } from '@ui'
import Modal from '@ui/Modal'

import '@components/JourneyList/Sort/index.scss'

const Sort = (): ReactElement => {
  const [opened, setOpened] = useState<boolean>(false)
  const [
    {
      sorting: { supported },
    },
  ] = useSettings()
  const [{ sorting: current }] = useParams()
  const [_, setSearchParams] = useSearchParams()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const sortOptions = supported.map(item => ({
    value: item,
    label: t(`journeyList.sorters.${item}`),
  }))

  const handleSort = (value: Sorting): void => {
    setSearchParams(current => ({ ...current, sorting: value }))
    setOpened(!opened)

    amplitude.results.clickSort(value)
  }

  const modalClassNames = (value: string): string =>
    bem('journey-list', 'sort-modal-content', { active: value === current })

  return (
    <div className="row items-center cell-9">
      <span className={bem('journey-list', 'sort-title')}>{t('journeyList.sort') + ':'}</span>
      {isMobile && current && (
        <div
          className="row items-center cell-9"
          onClick={() => {
            setOpened(!opened)
          }}
        >
          <span className={bem('journey-list', 'sort-value')}>{t(`journeyList.sorters.${current}`)}</span>
          <Icon name="chevron-down" size="small" />
        </div>
      )}
      {!isMobile && (
        <Dropdown
          items={sortOptions}
          value={current}
          onChange={handleSort}
          className={bem('journey-list', 'sort-dropdown')}
          focus={false}
          checkedMark
        />
      )}
      {isMobile && (
        <Modal
          opened={opened}
          onClose={() => {
            setOpened(!opened)
          }}
          title={t('journeyList.sort')}
          className={bem('journey-list', 'sort-modal')}
        >
          {sortOptions.map(({ value, label }) => (
            <div
              key={value}
              onClick={() => {
                handleSort(value)
              }}
              className={modalClassNames(value)}
            >
              <span>{label}</span>
              {current === value && <Icon name="checkout" size="medium" />}
            </div>
          ))}
        </Modal>
      )}
    </div>
  )
}

export default Sort
