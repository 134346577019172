import { PaymentProvider } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import useCreditCard from '@pages/Checkout/hooks/Payment/VGS/useCreditCard'
import useSavedCreditCard from '@pages/Checkout/hooks/Payment/VGS/useSavedCreditCard'

export const useVGSMethods = (): PaymentProvider => {
  const creditCard = useCreditCard()
  const savedCreditCard = useSavedCreditCard()

  return { creditCard, savedCreditCard }
}
