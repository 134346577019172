import { SeatsRequest } from '@api/seats'
import { SeatsList } from '@hooks/useSeatSelection'
import date from '@lib/date'
import utils from '@lib/utils'

const initialParams: SeatsRequest = {
  arrivalStation: '',
  departureStation: '',
  departureTime: null,
  arrivalTime: null,
  marketingCarrierCode: '',
  locale: '',
  currency: '',
}

const currencyIcons: Record<string, string> = {
  EUR: 'euro',
  USD: 'dollar',
  GBP: 'pound',
}

const supportLabels = ['WC', 'ES']

const getCurrencyIcon = (currency: Currency): string => currencyIcons[currency] ?? 'star-simple'

const getSeatsCount = (seats?: Record<string, Seat.Entry[]> | null): number => {
  if (!seats) return 0

  return Object.values(seats).reduce((acc, currentValue) => acc + currentValue.length, 0)
}

const getUniqueFares = (layout: Seat.Data): string[] => {
  const array = [...new Set(layout?.cars.flatMap(item => item.seats.map(el => el.fareClass)))]

  return array.filter(item => item !== null) as string[]
}

const getUniqueLevel = (layout: Seat.Data, fareClassCode: string): number[] => [
  ...new Set(
    layout?.cars.flatMap(item =>
      item.seats.filter(item => item.fareClass === fareClassCode).map(el => el.coordinates.z),
    ),
  ),
]

const buildSeatsParams = (connection: Connection | null, locale: Locale, currency: Currency): SeatsRequest => {
  if (!connection) return initialParams

  return {
    marketingCarrierCode: connection.marketingCarrier.code,
    departureStation: connection.departureStation.code,
    arrivalStation: connection.arrivalStation.code,
    departureTime: date.formatDateISO(date.parse(connection.departureTime, 'UTC')),
    arrivalTime: date.formatDateISO(date.parse(connection.arrivalTime, 'UTC')),
    locale,
    currency,
  }
}

const toUrlParams = (selected: SeatsList): Record<string, Partial<Seat.UrlParam[]>> => {
  const entries = Object.entries(selected).map(([key, value]) => [
    key,
    value.map(item => ({ price: item.price ?? 0, code: item.code })),
  ])

  return Object.fromEntries(entries)
}

const filterLayoutByFareClass = (layout: Seat.Data[], fareClassCode: string | null): any =>
  layout?.map(item => ({
    ...item,
    cars: [
      {
        ...item.cars,
        seats: item.cars[0].seats.filter(el => el.fareClass === fareClassCode || supportLabels.includes(el.label)),
      },
    ],
  }))

const sumPrice = (seats: Seat.Entry[]): number => utils.array.sum(seats, seat => Number(seat.price))

const sumOutboundPrice = (selected: SeatsList, connection: Connection | null, currency: Currency): Money =>
  Object.values(selected)
    .flatMap(item => item.map(el => ({ fareClass: el.fareClass, price: el.price })))
    .reduce(
      (acc, curr): Money => {
        const current = connection?.fares.find(el => el.fareClass.code === curr.fareClass)

        return {
          fractional: acc.fractional + (current?.price.fractional ?? /* istanbul ignore next */ 0) + Number(curr.price),
          currency,
        }
      },
      { fractional: 0, currency },
    )

const MAX_PRICE_CATEGORY = 3

const getPriceCategories = (seats: Seat.Entry[]): Record<number, number> => {
  const prices = seats.filter(seat => seat.price != null && seat.price > 0).map(seat => seat.price as number)

  return [...new Set<number>(prices)]
    .sort((a, b) => a - b)
    .reduce((mem, price, index) => ({ ...mem, [price]: Math.min(index + 1, MAX_PRICE_CATEGORY) }), {})
}

const flatten = (seats?: SeatsList): Seat.Entry[] => Object.values(seats ?? {}).flat()

export default {
  getSeatsCount,
  getUniqueFares,
  getUniqueLevel,
  toUrlParams,
  buildSeatsParams,
  filterLayoutByFareClass,
  sumPrice,
  sumOutboundPrice,
  getPriceCategories,
  getCurrencyIcon,
  flatten,
}
