import { useField } from 'formik'
import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import { Validator } from '@hooks/useValidators'
import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import Card from '@pages/Checkout/Extras/Card'
import Controls from '@pages/Checkout/Extras/Card/Controls'
import Description from '@pages/Checkout/Extras/Card/Description'
import Details from '@pages/Checkout/Extras/Card/Details'
import Footer from '@pages/Checkout/Extras/Card/Footer'
import useAncillaries from '@pages/Checkout/hooks/useAncillaries'
import { useSettings } from '@queries/settings'
import { Icon, Modal } from '@ui'
import Alert from '@ui/Alert'

export interface DefaultProps {
  name: KnownAncillary
  getDescription?: (ancillary: Ancillary.Item) => ReactNode
  getMaxCount?: (maxCount: number, ancillaries: Ancillary.Item[], code?: string) => number
  validate?: Validator
  outbound: Connection | null
  inbound: Connection | null
}

const Extended = ({ name, getDescription, getMaxCount, validate, ...rest }: DefaultProps): ReactElement => {
  const [{ reservation }] = useSettings()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const { opened, list, selected, maxCount, handleToggle, handleCancel, handleChange, initialValue } = useAncillaries({
    name,
    ...rest,
  })

  const countLimit = maxCount === selected.length ? 0 : maxCount
  const key = useMemo(() => name.toLowerCase(), [name])
  const [, { error, touched }, { setError, setTouched, setValue }] = useField({ name: `ancillaries.${name}`, validate })

  useEffect(() => {
    !reservation.enabled && setValue(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, reservation.enabled])

  const controls = (
    <div className={bem('extras', 'card-icon', { opened })}>
      <Icon name="chevron-right" size="large" />
    </div>
  )

  const description = (ancillary: Ancillary.Item): ReactNode => (
    <Description title={ancillary.name} description={ancillary.description} />
  )

  const details = useMemo(
    () =>
      list.map(item => (
        <Details
          key={item.code}
          ancillary={item}
          description={
            <>
              {getDescription && !!getDescription?.(item) && <span>{getDescription(item)}</span>}
              {isMobile && item.description && description(item)}
            </>
          }
          controls={
            <div className="row gap-3">
              {!isMobile && item.description && description(item)}
              <Controls
                ancillary={item}
                maxCount={getMaxCount?.(maxCount, selected, item.code) ?? countLimit}
                value={selected.filter(el => el.code === item.code).length}
                onChange={handleChange}
              />
            </div>
          }
        />
      )),
    [list, getDescription, isMobile, getMaxCount, maxCount, selected, countLimit, handleChange],
  )

  return (
    <div className={bem('extras', 'section')}>
      {error && touched && (
        <div className="cell mb-4">
          <Alert
            message={error}
            type="error"
            onClose={() => {
              setError(undefined)
              setTouched(false, false)
            }}
          />
        </div>
      )}
      <Card
        icon={ancillaryUtils.getIcon(name)}
        title={t(`extras.ancillary.${key}.title`)}
        price={list.map(item => item.price)}
        controls={controls}
        onClick={handleToggle}
        details={opened && details}
      />
      {isMobile && (
        <Modal
          opened={opened}
          title={t(`extras.ancillary.${key}.title`)}
          onClose={handleCancel}
          className={bem('extras', 'card-modal')}
          footer={<Footer onClick={handleToggle} onClose={handleCancel} />}
        >
          <div className={bem('extras', 'card-modal-content')}>{details}</div>
        </Modal>
      )}
    </div>
  )
}

export default Extended
