import { Field } from 'formik'
import React, { ReactElement, useCallback, useMemo } from 'react'

import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { useSettings } from '@queries/settings'
import { DropdownItem } from '@ui/Dropdown'
import DropdownField from '@ui/Dropdown/Field'
import InputField from '@ui/Input/Field'

interface DocumentTypeProps {
  name: string
}

const DocumentType = ({ name }: DocumentTypeProps): ReactElement => {
  const { t } = useTranslation()
  const [{ governmentId }] = useSettings()
  const { required } = useValidators()

  const translateType = useCallback(
    (type: GovernmentIdType) => {
      return t(`checkout.passengerDetails.governmentIdTypes.${type}`)
    },
    [t],
  )

  const options = useMemo(
    () =>
      governmentId?.types.map<DropdownItem<string>>(value => ({
        value,
        label: translateType(value),
      })),
    [governmentId?.types, translateType],
  )

  return (
    <>
      <Field
        component={DropdownField}
        label={t('checkout.passengerDetails.governmentIdType')}
        placeholder={t('checkout.passengerDetails.governmentIdType')}
        name={`${name}.governmentIdType`}
        items={options}
        required
      />
      <Field
        component={InputField}
        label={t('checkout.passengerDetails.governmentId')}
        name={`${name}.governmentId`}
        validate={required}
        required
      />
    </>
  )
}

export default DocumentType
