import React, { ReactElement } from 'react'

import Brand from '@components/Footer/Brand'
import Help from '@components/Footer/Help'
import Menu from '@components/Footer/Menu'
import PaymentBrands from '@components/Footer/PaymentBrands'
import Security from '@components/Footer/Security'
import Divider from '@ui/Divider'

import '@components/Footer/index.scss'

const Footer = (): ReactElement => {
  return (
    <div className="page-footer column items-center p-sm-4">
      <div className="page-footer__content column gap-6">
        <div className="row space-between column-sm gap-6">
          <div className="cell cell-sm-12 pb-sm-6 no-grow page-footer__brand-container">
            <Brand />
          </div>
          <div className="cell">
            <Menu />
          </div>
          <div className="cell-3 cell-sm-12">
            <Help />
          </div>
        </div>
        <div className="cell-12">
          <Divider />
        </div>
        <div className="row space-between column-sm gap-sm-4">
          <div className="cell">
            <PaymentBrands />
          </div>
          <div className="cell no-grow">
            <Security />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
