import React, { Fragment, ReactElement, useMemo } from 'react'

import { IdFields } from '@enums'
import Citizenship from '@pages/Checkout/PassengerDetails/PassengerFieldset/Citizenship'
import DocumentType from '@pages/Checkout/PassengerDetails/PassengerFieldset/DocumentType'
import ExpirationDate from '@pages/Checkout/PassengerDetails/PassengerFieldset/ExpirationDate'
import IssuingLocation from '@pages/Checkout/PassengerDetails/PassengerFieldset/IssuingLocation'
import { useSettings } from '@queries/settings'

interface GovernmentIdFieldsProps {
  name: string
}

const GovernmentId = ({ name }: GovernmentIdFieldsProps): ReactElement => {
  const [{ governmentId }] = useSettings()

  const documentFields: Record<GovernmentIdFields, React.ReactNode> = useMemo(
    () => ({
      [IdFields.Type]: <DocumentType name={name} />,
      [IdFields.Citizenship]: <Citizenship name={name} />,
      [IdFields.Location]: <IssuingLocation name={name} />,
      [IdFields.ExpirationDate]: <ExpirationDate name={name} />,
    }),
    [name],
  )

  return (
    <>
      {governmentId.fields.map(key => (
        <Fragment key={key}>{documentFields[key]}</Fragment>
      ))}
    </>
  )
}

export default GovernmentId
