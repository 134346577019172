export type SegmentedData = Pick<Connection, 'segments'>

const getCarrierCodes = (connection: SegmentedData | null): string[] | undefined =>
  connection?.segments.map(({ marketingCarrier }) => marketingCarrier.code)

const getCarriers = (connection: SegmentedData | null, carriers: MarketingCarrier[]): MarketingCarrier[] =>
  carriers.filter(c => [...new Set(getCarrierCodes(connection))].includes(c.code))

export default {
  getCarriers,
}
