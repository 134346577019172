import React, { ReactElement, ReactNode } from 'react'

import Money from '@components/Money'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seat from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

import '@components/SeatSelection/Legend/index.scss'

interface LegendProps {
  amenities?: ReactNode
  hasPaidSeats: boolean
  priceCategories: Record<number, number>
}

const Legend = ({ amenities, hasPaidSeats, priceCategories }: LegendProps): ReactElement => {
  const { t } = useTranslation()
  const [{ currency }] = useParams()

  return (
    <div className="seat-selection__legend column row-lg">
      <div className="seat-selection__legend-amenities row gap-2">{amenities}</div>
      <div className="seat-selection__legend-info gap-3 wrap row cell-lg-6">
        {hasPaidSeats &&
          Object.entries(priceCategories).map(([price, count]) => (
            <div key={price} className="row items-center">
              <div className={bem('seat-selection', 'legend-seat', { paid: true })}>
                <span className="row items-center mb-1 mb-lg-2">
                  {utils.common.times(count, index => (
                    <Icon
                      className={bem('seat-selection', 'legend-seat-sign')}
                      key={index}
                      name={seat.getCurrencyIcon(currency)}
                      size="small"
                    />
                  ))}
                </span>
              </div>
              <span>
                +<Money fractional={Number(price)} currency={currency} />
              </span>
            </div>
          ))}
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat')} />
          <span>{t(hasPaidSeats ? 'seats.free' : 'seats.available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { disabled: true })}>
            <Icon name="cross" size="medium" />
          </div>
          <span>{t('seats.not_available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { selected: true })}>
            <Icon name="checkout" size="medium" />
          </div>
          <span>{t('seats.your_seat')}</span>
        </div>
      </div>
    </div>
  )
}

export default Legend
