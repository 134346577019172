import React, { ReactElement } from 'react'

import Counter from '@components/Counter'
import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'

interface ControlsProps {
  maxCount: number
  value: number
  ancillary: Ancillary.Item
  onChange: (isIncrease: boolean, count: number, ancillary: Ancillary.Item) => void
}

const Controls = ({ ancillary, maxCount, onChange, value }: ControlsProps): ReactElement => {
  const isMobile = useIsMobile()

  return (
    <div className="extras__card-controls-details row items-center space-between">
      <Counter
        min={0}
        max={maxCount}
        value={value}
        onChange={count => {
          onChange(count > value, count, ancillary)
        }}
      />
      {!isMobile && (
        <b>
          <Money fractional={ancillary.price.fractional} currency={ancillary.price.currency} />
        </b>
      )}
    </div>
  )
}

export default Controls
